import { registerLicense, L10n } from "@syncfusion/ej2-base";
import { closest, remove, addClass } from '@syncfusion/ej2-base';
import { Schedule, Week, Month, TimelineViews, TimelineMonth, Resize, DragAndDrop, ActionEventArgs, CellClickEventArgs, ResourceDetails } from '@syncfusion/ej2-schedule';
import $ from "jquery";
import { DragAndDropEventArgs, TreeView } from '@syncfusion/ej2-navigations';

interface SchedulerOptions {
    wrapper: string | HTMLElement;
}
let dataSource: object [] = [];
class SchedulerPlanner {

    constructor({ wrapper }: SchedulerOptions) {
        try {
            if (typeof $ === 'undefined') {
                throw new Error('jQuery is not defined.');
            }

            console.log("wraaaper", wrapper);

            registerLicense(
                "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkBjUXtccnxdT2Vb"
            );

           
        } catch (e) {
            console.error(e);
        }
    }
    loadScheduler(shift_records: object []) {
        const isReadOnly = function (endDate) {
            return (endDate < new Date(2024, 6, 31, 0, -3));
        };
        let data: object [] = [];
        Schedule.Inject( TimelineViews, Week, TimelineMonth, Month, Resize, DragAndDrop);
        // L10n.load({
        //     'en-US': {
        //         'schedule': {
        //             'saveButton': 'Add',
        //             'cancelButton': 'Close',
        //             'deleteButton': 'Remove',
        //             'newEvent': 'Add Event',
        //         },
        //     }
        // });                         
        let scheduleObj: Schedule = new Schedule({
            width: '90%',
            height: '800px',
            selectedDate: new Date(),
            cssClass: 'schedule-overview schedule-drag-drop',
            views: ['TimelineWeek', 'Week', 'TimelineMonth', 'Month'], 
            currentView: 'TimelineWeek',
            eventSettings: {
                dataSource: data,
            },
            group: {
                enableCompactView: false,
            },
            popupOpen: (args) => { // renderizado ao clicar em um horario
                let data = args.data;
                // console.log('popupOpen',data)
                if (args.type === 'QuickInfo' || args.type === 'Editor' || args.type === 'RecurrenceAlert' || args.type === 'DeleteAlert') {
                    let target = args.element?.[0] ?? args.target;
                    if (target && target.classList.contains('e-work-cells')) {
                        if ((target.classList.contains('e-read-only-cells')) || (!scheduleObj.isSlotAvailable(data))) {
                            args.cancel = true;
                        }
                    }
                    else if (target && target.classList.contains('e-appointment') &&    
                        (isReadOnly(data?.EndTime))) {
                        args.cancel = true;
                    }
                }
            },
            renderCell: (args) => { // renderiza as celulas
                // console.log('renderCell',args)
                if (args.element && args.element.classList.contains('e-work-cells')) {
                    if (args.date < new Date(2024, 6, 31, 0, 0)) {
                        args.element.setAttribute('aria-readonly', 'true');
                        args.element.classList.add('e-read-only-cells');
                    }
                }
                if (args.elementType === 'emptyCells' && args.element && args.element.classList.contains('e-resource-left-td')) {
                    let target = args.element.querySelector('.e-resource-text');
                    if (target) {
                        target.innerHTML = '<div class="name">Rooms</div><div class="type">Type</div><div class="capacity">Capacity</div>';
                    }
                }
            },
            eventRendered: (args) => { // renderiza o evento no scheduler e atualiza tela 
                let data = args.data;
                console.log('eventRendered',data)
                
                if (isReadOnly(data.EndTime)) {
                    args.element.setAttribute('aria-readonly', 'true');
                    args.element.classList.add('e-read-only');
                }

            },
            actionBegin: onActionBegin
        });

        scheduleObj.appendTo('#scheduler');

        let Shiftrecords: { [key: string]: Object }[] = (shift_records as any);
        console.log('Shiftrecords', Shiftrecords);
        let treeObj: TreeView = new TreeView({
            fields: { dataSource: Shiftrecords, id: 'name', text: 'description', child: 'child', },
            allowDragAndDrop: true,
            allowMultiSelection: true,
            nodeDragStop: onTreeDragStop,
            nodeDragging: onTreeDrag,
            nodeDragStart: onTreeDragStart,
            nodeSelecting: onItemSelecting,
        });
        treeObj.appendTo('#tree');
        let isTreeItemDropped: boolean = false;
        let draggedItemId: string = '';

    function onItemSelecting(args: any): void {
        args.cancel = true;
    } 

    function onTreeDrag(event: any): void {
        if (scheduleObj.isAdaptive) {
            let classElement: HTMLElement | null = scheduleObj.element.querySelector('.e-device-hover');
            if (classElement) {
                classElement.classList.remove('e-device-hover');
            }
            if (event.target.classList.contains('e-work-cells')) {
                addClass([event.target], 'e-device-hover');
            }
        }
    }

    function onActionBegin(event: ActionEventArgs): void {
        if (!event || !event.requestType) {
            return;
        }

        if (event.requestType === 'eventCreate' && isTreeItemDropped || event.requestType === 'eventChange') {
            const treeViewDataSource = treeObj.fields.dataSource as Record<string, any>[];
            if (!treeViewDataSource) {
                return;
            }

            const filteredPeople: Record<string, any>[] =
                treeViewDataSource.filter((item: any) => item?.Id !== parseInt(draggedItemId, 10));
            treeObj.fields.dataSource = filteredPeople;

            const elements: NodeListOf<HTMLElement> = document.querySelectorAll('.e-drag-item.treeview-external-drag');
            if (!elements) {
                return;
            }

            for (let element of Array.from(elements)) {
                if (element) {
                    remove(element);
                }
            }
        }
    }

    function onTreeDragStop(event: DragAndDropEventArgs): void {
        let treeElement: Element = <Element>closest(event.target, '.e-treeview');
        let classElement: HTMLElement | null = scheduleObj.element.querySelector('.e-device-hover');
        if (classElement) {
            classElement.classList.remove('e-device-hover');
        }
        if (!treeElement) {
            event.cancel = true;
            let scheduleElement: Element = <Element>closest(event.target, '.e-content-wrap') ||
                <Element>closest(event.target, '.e-all-day-row');
            if (scheduleElement) {
                let treeviewData: Record<string, any>[] =
                    treeObj.fields.dataSource as Record<string, any>[];
                if (event.target.classList.contains('e-work-cells')) {
                    let filteredData: Record<string, any>[] = [];
                    if (event.draggedNodeData && event.draggedNodeData.id) {
                        console.log('treeviewData',treeviewData, String(event.draggedNodeData.id).startsWith('SHIFT'))
                        if (String(event.draggedNodeData.id).startsWith('SHIFT')) {
                            filteredData = treeviewData.filter((item: any) => item.name == event.draggedNodeData.id);
                        } else {
                            filteredData = treeviewData.map((doc: any) => {
                                console.log('doc',doc.child)
                                if (doc && doc.child) {
                                    return doc.child.filter((item : any) => item.name === event.draggedNodeData.id);
                                }
                            }).flat()
                        }
                    }
                    // console.log('filteredData', filteredData);
                    let cellData: CellClickEventArgs = scheduleObj.getCellDetails(event.target);
                    // console.log('cellData', cellData);
                    let resourceDetails: ResourceDetails = scheduleObj.getResourcesByIndex(cellData.groupIndex as number);
                    // console.log('resourceDetails', scheduleObj);
                    let eventData: Record<string, any> = {
                        Subject: filteredData[0].subject,
                        StartTime: cellData['startTime'],
                        EndTime: cellData['endTime'],
                        IsAllDay: filteredData[0].isAllDay,
                        Description: filteredData[0].description,
                    };
                    if(String(event.draggedNodeData.id).startsWith('SHIFT')){
                        eventData = filteredData[0].child.map((doc: any) => {
                            let doc_start = new Date(doc.start_time);
                            let doc_end = new Date(doc.end_time);
                            let StartTimeForCell: Date | null = convergeDate(cellData['startTime'], doc_start)
                            console.log('StartTimeForCell', StartTimeForCell)
                            let endTimeForCell: Date | null = convergeDate(cellData['endTime'], doc_end)
                            console.log('endTimeForCell', endTimeForCell)
                            return {
                                Subject: doc.subject,
                                StartTime: StartTimeForCell,
                                EndTime: endTimeForCell,
                                IsAllDay: doc.isAllDay,
                                Description: doc.description,
                            }
                        })
                    } else {
                        let doc_start = new Date(filteredData[0].start_time);
                        let doc_end = new Date(filteredData[0].end_time);
                        // console.log('doc_start',doc_start, 'doc_end', doc_end)
                        eventData = {
                            Subject: filteredData[0].subject,
                            StartTime: convergeDate(cellData['startTime'], doc_start),
                            EndTime: convergeDate(cellData['endTime'], doc_end),
                            IsAllDay: filteredData[0].isAllDay,
                            Description: filteredData[0].description,
                        }

                    }

                    // scheduleObj.openEditor(eventData, 'Add', false);
                    scheduleObj.addEvent(eventData);
                    isTreeItemDropped = true;
                    draggedItemId = event.draggedNodeData.id as string;
                    scheduleObj.refreshEvents();
                }
            }
        }
        document.body.classList.remove('e-disble-not-allowed');
    }
    function onTreeDragStart() {
        document.body.classList.add('e-disble-not-allowed');
      }
       
    }
}

/**
 * Combina a data de `date` com a hora de `time` para criar uma nova data.
 *
 * @function convergeDate
 * @param {Date | null} date - A data que fornece o ano, mês e dia para a nova data. Se `null`, a função retornará `null`.
 * @param {Date | null} time - A data que fornece a hora, minuto e segundo para a nova data. Se `null`, a função retornará `null`.
 * @returns {Date | null} - Uma nova data combinando a data de `date` com a hora de `time`, ou `null` se qualquer um dos parâmetros for `null`.
 * 
 * @example
 * const date = new Date('Mon Sep 16 2024 13:30:00 GMT-0300');
 * const time = new Date('Mon Sep 19 2024 18:20:00 GMT-0300');
 * const newDate = convergeDate(date, time);
 * console.log(newDate.toString()); // Output: Mon Sep 16 2024 18:20:00 GMT-0300
 */
const convergeDate = (date: Date | null, time: Date | null): Date | null => {
    if (!date || !time) {
        return null;
    }
    try {
        // Extraí as partes da data e hora
        let dateYear = date.getFullYear();
        let dateMonth = date.getMonth();
        let dateDay = date.getDate();
        let timeHours = time.getHours();
        let timeMinutes = time.getMinutes();
        let timeSeconds = time.getSeconds();

        // Cria e retorna uma nova data
        return new Date(dateYear, dateMonth, dateDay, timeHours, timeMinutes, timeSeconds);
    } catch (error) {
        console.error('convergeDate', error);
        return null;
    }
}
// @ts-ignore
(frappe as any).provide("frappe.shift");
// @ts-ignore
(frappe as any).shift.SchedulerPlanner = SchedulerPlanner;

export default SchedulerPlanner;
