import { registerLicense, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
	Schedule,
	Day,
	Week,
	WorkWeek,
	Month,
	Agenda,
	DragAndDrop,
	TimelineViews,
	Resize,
} from "@syncfusion/ej2-schedule";
import $ from "jquery";
import { Button } from "@syncfusion/ej2-buttons";
import { enableRipple } from "@syncfusion/ej2-base";

interface Shift_load_Options {
	wrapper: string | HTMLElement;
}

let dataSource: object[] = [];
let data: object[] = [];

class Shift_load {
	constructor({ wrapper }: Shift_load_Options) {
		try {
			if (typeof $ === "undefined") {
				throw new Error("jQuery is not defined.");
			}

			registerLicense(
				"ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkBjUXtccnxdT2Vb"
			);

			console.log("load");
		} catch (e) {
			console.error(e);
		}
	}
	cleanscheduler() {
		$("#shift").empty();
	}
	loadScheduler(data_shift: object[]) {
		const isReadOnly = function (endDate) {
			return endDate < new Date(2024, 6, 31, 0, -3);
		};
		Schedule.Inject(Day, TimelineViews);
		enableRipple(true);
		dataSource.push(...data_shift);
		let scheduleObj: Schedule = new Schedule({
			height: "800px",
			width: "100%",
			allowMultiDrag: true,
			selectedDate: new Date(),
			eventSettings: { dataSource: data_shift },
			views: ["TimelineDay", "Day"],
			popupOpen: (args) => {
				// renderizado ao clicar em um horario
				let data = args.data;
				console.log("popupOpen", args);
				console.log(
					"scheduleObj.eventWindow.recurrenceEditor",
					scheduleObj.eventWindow
				);
				if (
					args.type === "QuickInfo" ||
					args.type === "Editor" ||
					args.type === "RecurrenceAlert" ||
					args.type === "DeleteAlert"
				) {
					let target = args.element?.[0] ?? args.target;
					if (target && target.classList.contains("e-work-cells")) {
						if (
							target.classList.contains("e-read-only-cells") ||
							!scheduleObj.isSlotAvailable(data)
						) {
							args.cancel = true;
						}
					} else if (
						target &&
						target.classList.contains("e-appointment") &&
						isReadOnly(data?.EndTime)
					) {
						args.cancel = true;
					}
				}
			},
			renderCell: (args) => {
				// renderiza as celulas
				// console.log('renderCell',args)
				if (
					args.element &&
					args.element.classList.contains("e-work-cells")
				) {
					if (args.date < new Date(2024, 6, 31, 0, 0)) {
						args.element.setAttribute("aria-readonly", "true");
						args.element.classList.add("e-read-only-cells");
					}
				}
				if (
					args.elementType === "emptyCells" &&
					args.element &&
					args.element.classList.contains("e-resource-left-td")
				) {
					let target = args.element.querySelector(".e-resource-text");
					if (target) {
						target.innerHTML =
							'<div class="name">Rooms</div><div class="type">Type</div><div class="capacity">Capacity</div>';
					}
				}
			},
			eventRendered: (args) => {
				// renderiza o evento no scheduler e atualiza tela
				let data = args.data;
				console.log("eventRendered", data);

				if (isReadOnly(data.EndTime)) {
					args.element.setAttribute("aria-readonly", "true");
					args.element.classList.add("e-read-only");
				}
			},
			actionBegin: (args) => {
				console.log("requestType", args.requestType);
				if (args.requestType === "eventRemove") {
					dataSource = dataSource.filter(
						(doc) => doc["Id"] !== args.data[0].Id
					);
				}
				if (
					args.requestType === "eventCreate" ||
					args.requestType === "eventChange"
				) {
					let data = void 0;
					if (args.requestType === "eventCreate") {
						data = args.data[0];
						console.log("data", data);
						dataSource.push(data as any);
					} else if (args.requestType === "eventChange") {
						data = args.data;
						let exists = dataSource.filter(
							(doc) => doc["Id"] === (data as any).Id
						);
						if (exists && dataSource.length) {
							dataSource.map((doc) => {
								if (doc["Id"] === (data as any).Id) {
									doc["StartTime"] = (data as any).StartTime;
									doc["EndTime"] = (data as any).EndTime;
									doc["IsAllDay"] = (data as any).IsAllDay;
									doc["Subject"] = (data as any).Subject;
									doc["Location"] = (data as any).Location;
									doc["Description"] = (
										data as any
									).Description;
								}
							});
						}
					}
					if (data !== null && data !== undefined) {
						if (!scheduleObj.isSlotAvailable(data)) {
							args.cancel = true;
						}
					}
				}
			},
		});

		scheduleObj.appendTo("#shift");
		return scheduleObj;
		// Render initialized button
		// button.append('.e-toolbar-items.e-tbar-pos');
		// $('.e-toolbar-items.e-tbar-pos').append
	}
	loadData() {
		return dataSource;
	}
	setData(data: object[]) {
		console.log("data", data);
		this.cleanscheduler();
		dataSource = [];
		this.loadScheduler(data);
	}
}
// @ts-ignore
(frappe as any).provide("frappe.shift");
// @ts-ignore
(frappe as any).shift.Shift_load = Shift_load;

export default Shift_load;
