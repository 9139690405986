frappe.provide("nxerp.ui.toolbar");

document.addEventListener("DOMContentLoaded", function () {
    // Cria um evento customizado para o login
    const checkDefaultCompanyEvent = new Event('checkDefaultCompany');

    if (!sessionStorage.getItem('hasCalledCheckDefaultCompany')) {
        document.addEventListener('checkDefaultCompany', function () {
            nxerp.ui.toolbar.check_default_company_and_prompt();
            sessionStorage.setItem('hasCalledCheckDefaultCompany', 'true');
        });

        document.dispatchEvent(checkDefaultCompanyEvent);

    }
});

nxerp.ui.toolbar.check_default_company_and_prompt = function () {
    frappe.call({
        method: 'nxerp.public.py.toolbar.check_user_default_company',
        callback: function (response) {
            let default_company = response.message;
            if (default_company) {

                nxerp.ui.toolbar.company_settings_default();
            }
        },
        error: function (err) {
            frappe.msgprint(__('Erro ao verificar a empresa padrão: ') + err.message);
        }
    });
};

nxerp.ui.toolbar.company_settings_default = function (frm) {
    frappe.call({
        method: 'nxerp.public.py.toolbar.get_active_companies',
        callback: function (response) {
            let companies = response.message;
            if (companies && companies.length > 0) {
                let dialog = new frappe.ui.Dialog({
                    title: 'Selecione uma Empresa',
                    fields: [{
                        fieldtype: 'Table',
                        fieldname: 'company_table',
                        cannot_add_rows: true,
                        in_place_edit: false,
                        data: companies.map(company => ({
                            entity_name: company.entity_name,
                            name: company.name
                        })),
                        get_data: () => {
                            return companies.map(company => ({
                                entity_name: company.entity_name
                            }));
                        },
                        fields: [{
                            fieldtype: 'Data',
                            fieldname: 'entity_name',
                            label: 'Empresa',
                            in_list_view: true,
                            read_only: 1
                        }]
                    }]
                });

                dialog.show();

                // Hide the specific button (adjust the selector as necessary)
                dialog.$wrapper.find('.btn-open-row').closest('.col').hide();
                dialog.$wrapper.find('[data-fieldname="entity_name"]').addClass('disabled').css('pointer-events', 'none');
                dialog.$wrapper.find('.row-check').closest('.col').hide();


                dialog.$wrapper.find('.grid-row').on('click', function () {
                    let companyName = $(this).find('[data-fieldname="entity_name"]')[0].textContent;
                    let company = companies.find(c => c.entity_name === companyName);
                    if (company) {
                        frappe.call({
                            method: 'nxerp.public.py.toolbar.set_default_company',
                            args: {
                                company_name: company.name
                            },
                            callback: async function (response) {
                                const global = await frappe.db.get_doc('Global Defaults');
                                const global_value = global ? global.default_company : '';
                                if (global_value !== response.message) {
                                    location.reload();
                                }
                                dialog.hide();
                            },
                            error: function (err) {
                                frappe.msgprint(__('Erro ao definir a empresa padrão: ') + err.message);
                            }
                        });
                    }
                });
            } else {
                frappe.msgprint(__('Nenhuma empresa ativa encontrada.'));
            }
        },
        error: function (err) {
            frappe.msgprint(__('Erro ao obter empresas: ') + err.message);
        }
    });
};

